import { Theme, useMediaQuery } from '@mui/material';
import useDialog from 'modules/dialog/useDialog';
import useSession from 'modules/stores/session/useSession';
import { useLoginModal } from 'components/loginModal/hooks/useLoginModal';
import {ReactNode, useCallback, useEffect} from 'react';
import { SaveSearchModal } from 'components/searchAgent/components/saveSearchModal/SaveSearchModal';
import { usePostSearchAgent } from 'components/searchAgent/hooks/usePostSearchAgent';
import {ELoginPosition} from 'modules/gtmEvents/enums/login/ELoginPosition';
import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useLoginStore } from 'modules/stores/login/useLoginStore';

interface IReturn {
    show: () => void;
    modal: JSX.Element | ReactNode | null;
}

const useSearchAgent = (
    filterDataFromUrl: FilterDataFromUrlType,
    searchTitle?: string,
): IReturn => {
    const { session } = useSession();
    const { loginModal, showLoginDialog } = useLoginModal();
    const { onSend, isLoading } = usePostSearchAgent(filterDataFromUrl);
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const setLoginPosition = useLoginStore((store) => store.setLoginPosition);

    const {
        set: setSearchAgentShowLSItem,
        get: getSearchAgentShowLSItem,
        remove: removeSearchAgentShowLSItem,
    } = useLocalStorageItem('isSearchAgentClicked');

    const isLoggedIn = !!session;

    const {
        component: modal,
        show: showDialog,
        hide: hideDialog,
    } = useDialog({
        content: (
            <SaveSearchModal
                onClose={() => hideDialog()}
                searchName={searchTitle}
                isLoading={isLoading}
                onSend={onSend}
                email={session?.email || ''}
            />
        ),
        paperProps: {
            sx: {
                borderRadius: (theme) =>
                    isMobile ? theme.spacing(2, 2, 0, 0) : theme.spacing(2),
            },
        },
        dialogContentProps: {
            sx: {
                padding: isMobile ? 3 : 4,
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
    });

    useEffect(() => {
        if (getSearchAgentShowLSItem()) {
            showDialog();
            removeSearchAgentShowLSItem();
        }
    }, [
        showDialog,
        getSearchAgentShowLSItem,
        removeSearchAgentShowLSItem,
    ]);

    const onShowLoginDialog = useCallback(() => {
        setSearchAgentShowLSItem('true');
        setLoginPosition(ELoginPosition.SEARCH_AGENT);
        showLoginDialog();
    }, [setLoginPosition, showLoginDialog, setSearchAgentShowLSItem]);

    return {
        show: isLoggedIn ? showDialog : onShowLoginDialog,
        modal: isLoggedIn ? modal : loginModal,
    };
};

export default useSearchAgent;
